<div style="max-width: 350px; position: relative">
  <div mat-dialog-title>
    <div class="update-head">
      <h2 class="title-login">Queridos Distolovers</h2>
      <button mat-icon-button mat-dialog-close>
        <mat-icon>close</mat-icon>
      </button>
    </div>
    <mat-divider></mat-divider>
  </div>
  <div mat-dialog-content style="font-size: 14px">
    <p>
      La coyuntura en nuestro país, está generando una serie de cambios a los
      que nosotros en DISTO, no somos ajenos.
    </p>
    <p>
      Dentro de toda la cadena de emprendimiento que tiene DISTO, está
      involucrado socialmente, el desarrollo de la calidad de vida de las
      familias agroganaderas del Perú, en este caso de la Asociación Aspam, que
      beneficia a más de 120 familias dedicadas totalmente a la actividad
      lechera, dado que los insumos y la inestabilidad del dólar afectan
      directamente a los costos de produccion de la materia principal, que es la
      leche.
    </p>
    <p>
      Por ello, desde el 01/09 tendremos nuevos precios para mantener la calidad
      y servicio que siempre hemos brindado.
    </p>
    <p>Gracias por ser parte de la familia Disto</p>

    <div style="text-align: right">
      <img
        src="../../../../assets/images/Disto_firma.png"
        width="100px"
        alt=""
      />
    </div>
  </div>
  <div class="div-bottom">
    <img class="bottom-img" src="../../../assets/images/profile4.png" alt="" />
  </div>
</div>
