import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-after19-dialog',
  templateUrl: './after19-dialog.component.html',
  styleUrls: ['./after19-dialog.component.scss']
})
export class After19DialogComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
