<div style="max-width: 350px; position: relative;">
    <div mat-dialog-title>
        <div class="update-head">
            <h2 class="title-login">
                Hola, cheese lover!
            </h2>
            <button mat-icon-button mat-dialog-close>
                <mat-icon>close</mat-icon>
            </button>
        </div>
        <mat-divider></mat-divider>

    </div>
    <div mat-dialog-content style="font-size: 14px">
        <p>Gracias por tu preferencia Cheese Lover!</p>
        <p>Te recordamos que:</p>
        <ul style="padding: 0px 0px 0px 18px;">
            <li>El tiempo de entrega de nuestros productos es de <b>máximo 48 horas hábiles</b> después de haber
                recibido tu
                compra y confirmado el abono.</li>
            <li>El horario de atención para validar las compras es de <b>lunes a viernes de 7:00a.m. a 7:00 p.m.</b> Si
                tu
                compra la realizas fin de semana o feriado, tu pedido será procesado el siguiente día útil durante el
                transcurso del día.</li>
            <li>El horario de entrega de los pedidos es de <b>lunes a viernes de 9:00 am a 5:00pm (LOS SABADOS DE ENERO, FEBRERO Y MARZO NO HABRÁ REPARTOS)
</b></li>
        </ul>

        <div style="text-align: right;">
            <img src="../../../../assets/images/Disto_firma.png" width="100px" alt="">
        </div>
    </div>
    <div class="div-bottom">
        <img class="bottom-img" src="../../../assets/images/profile4.png" alt="">
    </div>
</div>