<div style="max-width: 250px; position: relative">
  <div mat-dialog-title>
    <div class="update-head">
      <h2 class="title-login">Hola, cheese lover!</h2>
      <button mat-icon-button mat-dialog-close>
        <mat-icon>close</mat-icon>
      </button>
    </div>
    <mat-divider></mat-divider>
  </div>
  <div mat-dialog-content class="w3-center" style="font-size: 1em">
    <p>
      Te recordarmos que en este horario solo podrás acceder a nuestro catálogo
      de productos.
    </p>

    <p>
      Te esperamos de Lunes a Viernes de 7:00 a 19:00 horas (LOS SABADOS DE
      ENERO, FEBRERO Y MARZO NO HABRÁ REPARTOS)
    </p>

    <div style="text-align: right">
      <img
        src="../../../../assets/images/Disto_firma.png"
        width="100px"
        alt=""
      />
    </div>
  </div>
  <div class="div-bottom">
    <img class="bottom-img" src="../../../assets/images/profile4.png" alt="" />
  </div>
</div>
